@import './Variables.scss';

.App {
  font-size: 2vmin;
    text-align: center;
  display: flex;
  // flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  background-color: $color-deep-purple;
  opacity: .95;
  align-items: center;
  justify-items: center;
  // height: 100vh;

  &-logo {
    height: 40vmin;
    pointer-events: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Break {
  // width: 75vw;
  width: 100%;
  height: 5px;
  background-color: $color-fiery-red;
  order: 3;
  margin: 1vh 0;
  // margin: 3px 0;
  // padding: 3px 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Page {
  height: 90%;
  width: 90%;
  max-width: 1300px;
  background-color: $color-off-white;
  margin: 30px  auto;
}