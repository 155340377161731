$color-fiery-red: #FC6471;
$color-off-white: #f0ece5;
$color-charcoal: #3f3f3f;
$color-blue: #3CB0CD;
$color-deep-purple: #09070D;

// $color-off-white: #f9f7f1;
// $color-charcoal: #3f3f3f;
// $color-turqoise: #35cac9;;
// $color-deep-blue: #282c34;

// $color-bronze: #bc8034;
// $color-off-white: #F0ece5;
// $color-charcoal: #3f3f3f;
// $color-turqoise: #c8f0bc;
// $color-deep-blue: #93a3b1;
// C2EFB3 tea green

// $color-bronze: #cf974f;
// $color-off-white: #f0ece5;
// $color-charcoal: #3f3f3f;
// $color-turqoise: #5b8e7d;
// $color-deep-blue: #1d1528;