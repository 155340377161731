@import "./Variables.scss";

.Body {
    order: 4;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    background-color: $color-off-white;
    align-items: center;
    color: $color-charcoal;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0 3px 10px rgba($color: $color-off-white, $alpha: .3);


    &-Row {
        min-height: 10vh;
        width: 100%;
        display: flex;
        margin: .5vh 0;
    }
    &-Left {
        // font-size: calc(3px + 1vmin);
        order: 1;
        width: 30%;
        color: $color-charcoal;
        font-weight: 800;
        // padding: 5px ;
    }
    &-Right {
        order: 2;
        width: 70%;
        text-align: left;
        padding-right: 20px;
        margin-right: 2vmin;
    }

    &-Break {
        width: 100%;
        height: 2px;
        background-color: $color-charcoal;
        opacity: .45;
        margin: 1vh 0;
      }
  }