@import "./Variables.scss";

.Item {
    &-Title {
        padding-right: 20px;
        text-align: left;
        font-weight: 800;
    }
    
    &-DateReceived {
        padding-right: 20px;
        text-align: left;
        font-style: italic;
        margin-bottom: 1em;
        // font-size: 1em;
    }
    
    &-Descriptor {
        padding-right: 20px;
        text-align: left;
        color: $color-charcoal;
        margin-bottom: 1em;
    }
}