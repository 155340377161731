@import './Variables.scss';

.Header {
  order: 2;
  font-size: calc(3px + 2vmin);
  color: $color-charcoal;
  background-color: $color-off-white;
  min-height: 10vh;
  text-align: left;
  border-radius: 2px 2px 0 0;
  box-sizing: border-box;
  box-shadow: 0 3px 10px rgba($color: $color-off-white, $alpha: 0.3);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  a {
    color: $color-blue;
  }

  img {
    border-radius: 75px;
    // background-color: red;
    width: 125px;
    height: 125px;
    order: 1;
    align-self: center;
  }

  &-NameTag {
    display: flex;
    flex-flow: column nowrap;
    order: 2;
    height: 100%;
    padding: 1vh 0 0 10px;
    align-self: baseline;
  }

  &-Name {
    order: 2;
    font-size: calc(10px + 2vmin);

    font-weight: 700;
    // color: $color-bronze;
  }

  &-Title {
    order: 3;
    font-style: italic;
  }

  &-Left {
    float: left;
    display: flex;
    padding: 20px 0 20px 25px;
    margin: 1vmin 0;
    align-items: center;
  }

  &-Right {
    float: right;
    padding: 20px 25px 25px 0;
    margin: 1vmin 0;
    align-self: center;
  }

  &-Links {
    width: 100%;
    align-self: center;
    display: flex;
    flex-flow: row;
  }

  &-Contact {
    align-items: center;

    padding:  0 0 0 1vh;
  }
}
