@import "./Variables.scss";

.Bullet-Point {
    padding-right: 3px;
}

.Job {
    padding-right: 20px;
    text-align: left;
    
    &-Title {
        font-weight: 800;
        // font-size: calc(3px + 1vmin);

    }
    
    &-Dates {
        font-style: italic;
        margin-bottom: 1em;
    }
    
    &-Description {
        color: $color-charcoal;
        margin-bottom: 1em;
        display: flex;
        
    }

    &-Responsibilities-Header {
        color: $color-deep-purple;
        font-weight: 800;
        opacity: .85;
        
    }

    &-Responsibilities {
        margin-left: 2vw;
        // display: flex;
        direction: column;
        margin-bottom: 1em;
    }

    &-Responsibility-Row {
        display: flex;
    }
}

.Projects-Header {
    color: $color-deep-purple;
    font-weight: 800;
    opacity: .85;
}

.Project {
    margin-left: 2vw;

    &_Name {
        color: $color-charcoal;
        font-weight: 800;
        opacity: .85;
    }
    
    &_Technologies {
        font-style: italic;
        margin-bottom: 1em;
    }
    
    &_Description {
        margin-bottom: 1em;
    }
}